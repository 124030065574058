<template>
  <div class="login_All">
    <div class="login_container">
      <div class="login_body">
        <div class="login-form">
          <div class="login-logo">
            <!-- <img class="logo" :alt="appName" src="../assets/logo.png"> -->
            <div class="font">{{appName}}</div>
          </div>
          <div class="login-video">
            <video id="video" muted="muted" style="width: 100%;height: 100%; border-radius: 10px; outline: none;" src="../assets/video/loginVideo.mp4" autoplay="autoplay" loop></video>
          </div>
          <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="80px">
            <div :loading="loading1||loading2||loading3" class="login-loadingClass">
              <span v-if="loading1">正在验证登录信息...</span>
              <span v-if="loading2">正在进入系统主页...</span>
              <span v-if="loading3">正在跳转认证中心...</span>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <!-- <div class="login-footer">© {{appName}} {{appVar}}</div> -->
    <!-- 背景动态点 -->
    <!-- <div class="container">
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
    </div> -->

  </div>
</template>

<style >
#app,
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
.login_All {
  margin: 0px;
  width: 100%;
  height: 100%;
  background-color: #3a73b1;
  overflow: hidden;
}
.login_container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 800px;
  margin: 0 auto;
  z-index: 1;
  transform: translate(-50%, -50%);
  box-shadow: -15px 20px 50px #09509e;
  background-image: linear-gradient(to bottom right, #09509e, #9dc8e5);
  border-radius: 10px;
}
.login_body {
  width: inherit;
  display: flex;
  box-shadow: 0px 20px 80px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.login-sidebox {
  width: 50%;
  background: url(../assets/login-left.png) 0 0 no-repeat #0e0e0e;
  padding: 60px;
  color: #fff;
  position: relative;
}

.login-logo {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  margin-left: 15rem;
}
.login-logo .logo {
  margin-right: 10px;
  width: 2rem;
  height: 2.6rem;
}
.login-logo .font {
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem;
}

.login-title {
  margin-top: 20px;
}
.login-title h2 {
  font-size: 22px;
  font-weight: normal;
}
.login-title p {
  font-size: 12px;
  margin-top: 40px;
  line-height: 1.8;
  color: rgba(116, 27, 27, 0.8);
}

.login-form {
  width: 100%;
  padding: 60px;
  background: rgba(0, 0, 0, 0);
  border-radius: 8px;
}
.login-form h2 {
  font-size: 24px;
  color: #40485b;
  margin-bottom: 25px;
}
.login-oauth {
  display: flex;
  justify-content: space-around;
}
.login-form .el-divider {
  margin-top: 40px;
}

.login-footer {
  text-align: center;
  color: #999;
  margin-top: 50%;
}

.demo-user-item {
  display: flex;
  align-items: center;
  line-height: 1;
  padding: 10px 0;
}
.demo-user-item .icon {
  margin-right: 20px;
}
.demo-user-item .info h2 {
  font-size: 14px;
}
.demo-user-item .info p {
  color: #666;
  margin-top: 6px;
}

@media (max-height: 650px) {
  .login_container {
    position: static;
    transform: none;
    margin: 50px auto;
  }
}
.login-loadingClass {
  color: #efefef;
  text-align: center;
}
</style>

<script>
import newApi from '../utils/newApi'
import TOOL from "../utils/tool";
export default {
  data () {
    return {
      // appName: "新科汇智综合平台系统",
      appName: "智 慧 云 平 台",
      appVar: "1.1.0",
      loading1: false,
      loading2: false,
      loading3: false,
      form: {
        user: 'admin',
        password: ''
      },
      rules: {
        user: [
          { required: true, message: '账号：admin', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '密码：123456', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    login: function () {
      this.islogin = true;
      this.$router.replace({
        path: '/main'
      });
      //开启欢迎词
      // this.$message.success("Login Success 登录成功")
    },

    //新版登录
    isLogin () {
      this.loading1 = true;
      // let back = this.getParam('back', '/');
      let back = location.origin + location.pathname;
      let ticket = this.getParam('ticket');
      if (ticket) {
        this.doLoginByTicket(ticket, back);
      } else {

        newApi.getLoginInfo({}).then(result => {
          let data = result
          window.localStorage.setItem('loginId', data.loginId);
          setTimeout(() => {
            this.loading1 = false;
            //已经登录
            if (data.isLogin) {
              this.loading2 = true;
              this.login();
              setTimeout(() => {
                window.sessionStorage.setItem('Tit-token', data.tokenValue);
              }, 2000);
              TOOL.data.set("userName", data.userName);
              console.log("token值====》", data.tokenValue)
            } else {
              this.loading3 = true;
              setTimeout(() => {
                this.loading3 = false;
                this.goSsoAuthUrl();
              }, 2000);
            }
          }, 2000);
        })
      }
    },
    // 重定向至认证中心
    goSsoAuthUrl () {
      let data = {
        clientLoginUrl: location.href
      }
      newApi.getSsoAuthUrl(data).then(res => {
        location.href = res;
      })
    },
    // 根据ticket值登录
    doLoginByTicket (ticket, back) {
      newApi.doLoginByTicket({ ticket: ticket }).then(res => {
        localStorage.setItem('Tit-token', res);
        location.href = decodeURIComponent(back);
        this.login();
      })
    },
    getParam (name, defaultValue) {
      let query = window.location.search.substring(1);
      let vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        if (pair[0] == name) { return pair[1]; }
      }
      return (defaultValue == undefined ? null : defaultValue);
    }
  },
  mounted () {
    this.isLogin();
  }
}
</script>
